.judson-regular {
  font-family: "Judson", serif;
  font-weight: 400;
  font-style: normal;
}

.judson-bold {
  font-family: "Judson", serif;
  font-weight: bolder;
  font-style: normal;
}

.judson-regular-italic {
  font-family: "Judson", serif;
  font-weight: 400;
  font-style: italic;
}


.footer_{
  max-width:100%;
  /* background-color: #3C3737; */
  background-color: #444c51;
  color:white;
  margin-top: 4em !important;
  padding: 2em 3em !important;

}

.d-flex.flex-column.flex-sm-row.justify-content-between.email_address{
  width: 10rem !important;
}

.email{
    /* margin-left: 40%; */
    /* width: 100%; */
    padding-bottom: -10% !important;
    display: flex;
    justify-content: center;
    font-size:1.25em;
}

footer label.form-label {
  display: flex;
  /* justify-content: center; */
  margin-left: 1% !important;
  margin-top: 1em;
  font-size: 24px;
  color: white;
}

footer .form-control {
  /* margin-left: 10%; */
  min-width: 80%;
}

footer input[name="name"].form-control {
  margin-left: 2.85rem;
}

footer textarea[name="prayer_request"].form-control {
  margin-left: .75rem;
}

footer button.btn_s {
  width: 75%;
  padding: .3em;
}

img.give{
  height:3rem;
}
img.facebook, img.instagram{
  height:4rem;
  padding-left: -10px;
}
/* 
.space{
    position:relative;
    top:30vh;
}


.content{
  background-color:#566066;
  color:rgb(107, 103, 103);
  font-weight:bold;
  text-align:center;

}
.footerimages{
  height:6em; 
  width:auto;
  padding:.2em;
}

img.footerimages.mail{
  height:8em; 
} */