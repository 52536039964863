.row.story {
  margin: 0 5%;
}

.col.ourstory {
  width: 100%;
  margin: 0 5%;
}

.story_img img{
  
  object-fit:fill;
  margin:0 auto;
  margin-top:6em;
  height:auto;
  width:550px;
  margin-bottom:2em;
  margin-top:4em;

}

.story{
  width:100%;
  /* margin-top: 4em; */
  font-weight:bolder;
  font-size:42px;
  padding-top:1em;
  padding-bottom:1em;
  color:#566066;
}
.ourstory{
  font-size:32px;
  margin-bottom: 4em;
  text-align:left;
  color:#566066;
}
.col-12.align-self-center.story_img.img {
  margin-top: 5em;
}

@media screen and (max-width:770px){
  .story{
    font-size:1.5em;
    padding-left:1em;
   }

   .ourstory{
    font-size:1em;
   
  }
} 

@media screen  and (max-width: 990px) {
  .story_img img{
  object-fit:fill;
  margin:0 auto;
  margin:3em 0;
  height:auto;
  width:20em;
}
}