/* for fonts to add to class */
body {overflow-x: hidden;
}

.inter {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight:400;
  font-style: normal;
  font-variation-settings:"slnt" 0;
}
.judson-regular {
  font-family: "Judson", serif;
  font-weight: 400;
  font-style: normal;
}

.judson-bold {
  font-family: "Judson", serif;
  font-weight: bolder;
  font-style: normal;
}

.judson-regular-italic {
  font-family: "Judson", serif;
  font-weight: 400;
  font-style: italic;
}


html {
  margin: 0;
  padding: 0;
  background-color:white; 
  letter-spacing: .15rem;
  font-family: 'Roboto', sans-serif;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main_{
  height:100%;
}

.navbar{
  background-color: #566066;
}

.container-fluid.extended{
  background-color: #fff;
  position: absolute; 
  top: .5rem; 
  z-index: 1000; 
} 

.navbar-brand, .topnav{
  color:white;
}
a.nav-link{
  margin:1em;
  color:white;
}
.top_navigation a.nav-link:hover{
  color:white;
}
.top_navigation{
  display:flex;
 
}

.nav-item{
  list-style-type: none;
}

/* subnav */
.items a.nav-link{
  color:#5a2929;
  font-size: 1.5em;
  font-weight: bold;
}

.div#navbarNavDropdown{
  display:flex;
  justify-content: start;
}

.smallLogo{
  height:5em;
  width:auto;
  border-radius: 50%;
  /* margin-left: 4em; */
  margin-bottom: .5em;
}

.nav-link{
  color:#F1D9C9;
}

.navbar-nav .nav-link{
  padding-left: 2em;
}

a{
  text-decoration:none;
}

.navbar-toggler{
  background-color: #ffffff;
}

/* .jumbotron{
  width:100%;
  background-color: black;
  margin-top:6em;
} */
/* .container.main_page{
  margin: 0 !important;
  padding: 0 !important;
} */
.row.main_page{
  margin-left: 0 !important;
  margin-right:0 !important;
}
.col.main_page{
  margin-top: 2rem;
  padding: 5rem 0;
  width:100%;
}
.main_image{
  object-fit: fill !important;
  /* border-top:.15rem solid #B78282; */
  height:100%;
  width:100%;
  padding: 5rem 0;
  /* margin-top:1rem; */
  /* object-fit: fill !important;
  border-top:.15rem solid #B78282;
  height:75vh;
  width:100%;
  object-fit: cover; */
}

.container{
  margin:0;
  padding:0;
}

.three_cs{
  display: flex;
  align-items: left;
  width:50%;
}
.text {
  display:flex;
  position: absolute;
  top:4em; 
  left: 1em; 
  color: white;
  font-size: 3em;
  font-weight: bold;
  line-height: 3em;
}
.text ul {
  list-style-type: none;
  -webkit-text-stroke-width:1px;
  -webkit-text-stroke-color:#F0D8CC;
}

.compassion{
  font-size:2em; /*big*/
}
.connectivity{
  font-size:1.5em;/*small*/
  position:relative;
  left:.75em;
}
.community{
  font-size:1.75em;/*med*/
}

span.gl.judson-bold{
  font-size:3rem;
}

.intro_main{
  background: white;
  height: 100%;
  display: flex;
  align-items: end;
  width: 100%;
  margin: 20% 2em;
  margin-right:2em;
  border: 1px solid white;
}

.gl{
  color:#814A2F;
  font-size:2em;
}
.gl_intro{
  border-top:.15rem solid #B78282;
  background-color: #E5DADA;
  font-size:1.25em;
  line-height: 2em;
  padding:1.75em;
  /* color:#674C4C; */
  color:black;
  display: flex;
  /* align-items: center; */
  padding-top: 20%;
  justify-content: center;
  text-align: right;
}


.deuteronomy{
  color:#814A2F;
  margin: 0 auto;
  padding-top: 0 !important;
  padding: 4em 2em;
  text-align: center;
  font-size: 1.75rem;
}

.donations{
  margin: 5% 2%;
}
.donation_image{
  height:150px;
  width:250px;
  /* margin-left:4em; */
  /* object-fit: contain; */
}

.bags{
  transform: rotate(10deg);
}
.wipes{
  transform: rotate(-10deg);
}


.outreach{
  color:black;
  font-size: 1.74rem;
  min-height:80vh;
  display: flex;
  padding: 0 2em;
  background-color: #F3F3F3;
}

.outreach .title{
  font-size:4rem;
}

.lists{
  list-style-type: none;
  margin-left: 2em;
}

.lists li{
  margin-bottom:.5em;
}


/* upcoming event */

.col_events{
  background-color: #8F6767;
  min-height:100vh;
  padding-bottom: 10rem;
}


.col_events h2{
  color:white;
  font-size: 4rem;
  /* padding:4rem; */
  display: flex;
  /* margin: 4rem; */
}

.col.event_{
  margin-right:-10rem;
  z-index: 1;
  margin-left:2rem;
}
.row_event{
  border-bottom:2rem solid #B78282;
  background-color: #E5DADA;
  font-size:1.25em;
  line-height: 2em;
  padding:1.75em;
  margin-left: 3rem;
  color:black;
  min-height:20%;
  display: flex;
  margin-top:1em !important;
  /* max-width:50%; */
  align-items: center;
  justify-content: space-around;
  text-align: right;
}


img.row_img{
  margin-top:1rem !important;
  height: 48em;
  padding: 0 4rem;
  width: auto;
  display: flex;
}




/* vision mission */
.background{
  background-color: black;
  width:100%;
  height:30vh;
}
img.vision_mission{
  height:30vh;
  opacity: .8;
  width:100%;
  object-fit: cover;
}

.vision_mission_title{
  font-size:2em;
  background-color:#D9D9D9;
  color:#814A2F;
  height:40%;
  width:10em;
  margin-top:-60px;
  position: relative;
  z-index:4;
  margin-left:.75em;
  padding:0 .75em;
}
.values_title{
  font-size:2em;
  background-color:#D9D9D9;
  color:#814A2F;
  height:40%;
  width:10em;
  margin-top:-20px;
  position: relative;
  z-index:4;
  margin-left:.75em;
  padding:0 .75em;
}

.vission_mission_content{
  height:40vh;
  width:100%;
  font-size:2rem;
}
.vission_{
  display: flex;
  justify-content: center;
  margin: 2.5em;
}
.values_{
  color: #814A2F;
  /* border-bottom:2px solid #814A2F; */
}
/* pillars */
.pillars{
  display: flex;
  margin: 0 4em;
}
.pillar{
  font-size: 3em;
  text-align: center;
}
.pillar_content{
  padding-top: 2em;
}
.verse_{
  font-size: 1.25em;
  text-align: center;
}
.mercy{
  width:40%;
  background-color: #B78282;
  padding: 5em;
  position: relative;
  z-index: 1;
  margin-top: 8em;
}

.justice{
  width:60%;
  background-color: #D9CACA;
  padding: 5em;
  position: relative;
  z-index: 3;
  margin-top: 2em;
}

.service{
  width:40%;
  background-color: #EDE9E9;
  padding: 5em;
  position: relative;
  z-index: 2;
  margin-top: 10em;
}

.outreach_{
  padding-right:2rem;
}

.thank_you{
  font-size: 4rem;
  margin:4rem ;
}
/* about gracious love */
@media (max-width: 767.98px) {
 
  .three_cs{
    display:none;
  }
    .intro_main{
      background: white;
      height: 100%;
      margin: 15% auto;
  }
  .scripture{
    font-size: 1.5rem;
  }
 
  .outreach_{
    font-size: 1.25rem ; 
    margin:0 auto;
    text-align: center;
    padding:0;
  }
  .title {
    font-size: 1.75rem !important;
  }
  .donations{
    display:none;
  }
  .lists{
    list-style-type:circle;
    margin-left: .5em;
    font-size:.75em;
  }
  .vission_{
    font-size: 1.25rem;
    width: 80%;
    margin: 1.5em .75em;
    /* margin-top: */
  }
  .pillars{
    display: inline;
    margin: 0;
  }
  .mercy, .justice, .service{
    width:100% !important;
    margin-top: 1em !important;
  }

.vission_mission_content{
  height:62vh;
  width:100%;
  font-size:2rem;
}
}


@media (min-width: 768px) and (max-width: 991.98px) {

  .intro_main{
  background: white;
  height: 100%;
  margin: 15% auto;

  }
  .three_cs{
   font-size: .5em;
  }
  .vission_mission_content{
    height:62vh;
    width:100%;
    font-size:2rem;
  }
}

@media (min-width: 992px) {
  .intro_main{
    background: white;
    height: 100%;
    display: flex;
    align-items: end;
    width: 100%;
    margin: 15% 2em;
    margin-right:2em;
  }
}


@media(min-width: 300px) and (max-width:768px){
  .col.event_{
    margin: 0;
    padding: 0;
  }
  .col-4.main_page{
    display:none;

  }
 
}