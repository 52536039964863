h2{
  font-weight: 200px;
  color:#566066;
}

.bordered {
  padding: 2em;
  box-shadow: 0 6px 12px 3px #ddd;
  /* border:1px solid black; */
  margin-top: 2em;
  background-color: #f1d9c9;
  
}




.outer{ 
  margin-top: 2em;
  text-align: center;
  margin-left:10%;
  margin-right:10%;
}

.form-label{
  display: flex;
  /* justify-content: center; */
  margin-left: 25%;
  margin-top: 1em;
  font-size: 24px;
  color:#566066;
}

.form-control{
  margin-left: 25%;
  /* background-color: #fff; */
  
}

button.btn_s{
  text-transform: uppercase;
    font-weight: bolder;
    color: #566066;
    border: rgb(63, 60, 60);
    background-color: #fff;
}

@media screen and (max-width:768px){
  .message{
    width:100%;
 }
 .details{
  margin-bottom:2em;
 }
}