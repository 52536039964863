.prayer_page{
  color:#566066;
}

.prayer_title{
  text-align: center;
}

.verse{
  /* display:flex;*/
  padding:2em; 
  background-color: #e8e7ef;
}

img.church_img{
  object-fit:fill;
  margin:0 auto;
  margin-top:6em;
  height:327px;
  width:880px;

}

.prayers_headline{
  /* margin-top:4em; */
  padding-left:5em;
  min-width:20%;
  max-width:28%;
  color:#566066;
  background-color: #f1d9c9;
}
.pray{
  padding:2em;
  text-align: center;
}

.petition_list{
  margin-left: 4em;
}
/* 870px */
@media screen  and (max-width: 990px) {
  img.church_img{
  object-fit:fill;
  margin:0 auto;
  margin-top:6em;
  height:auto;
  width:20em;
}
}












