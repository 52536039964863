.card{
  max-width: 1240px;
  margin:0px auto;
  margin-top:4em;
  color:#566066;
}
.card-body{
  /* margin-top: 20%; */
  margin: 10% 0;
}

p.card-text{
  font-size:1.2em;
}

