
.mission{
  padding: 2em;
  box-shadow:0 6px 12px 3px #ddd
}
.vision_mission{
  font-family: 'GFS Didot',Georgia,"Times New Roman",Times,serif;
  font-size:2em;
  font-weight: bold;  
  margin-bottom:1em;
}

.mission_{
  font-size:.75em;
  font-family: "Gotham","Helvetica Neue",Helvetica,Arial,sans-serif;
}
/* ------------------ */
.carousel{
  box-sizing: border-box;
  width: 100%;
}

/* .jumbocarousel{
  margin-top:8em;
  max-width:100%;
  box-sizing: border-box;
} */

/* .jumbocarousel img{
  width:auto;
  object-fit: contain;
} */

/* .carousel-control-next-icon, .carousel-control-prev-icon{
  background-color: black;
} */

/* button.carousel-control-next, button.carousel-control-prev{
  width:5%;
} */


#mission{
  margin-top:1.5em;
  font-size: 2em;
}

.values_{
 line-height: .5em; 
 font-size:1em;
}

.mission-statement{
  font-size: 2em;
}
.ourmission{
  margin: 8em 0;
}

.carousel-control-next, .carousel-control-prev{
  width:45%;
}

@media screen and (max-width:820px){
  .carousel-control-next, .carousel-control-prev{
      width: 5%;
   }

   #mission{
    margin-top:.5em;
    font-size: 2.5em;
   }

   .mission-statement{
    font-size:1em;
   }
  
} 