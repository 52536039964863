
.col{
  display:flex;
  flex-wrap:wrap;
  justify-content: center;
  width:auto;
  margin-top:8rem
}

.photo{
width:350px;
height:300px;
margin:1px;
object-fit: contain;
}


